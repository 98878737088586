import React from "react"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MiniPage from "../components/minipage"

const text = `For those just starting to consider a technology career, it's best to avoid the temptation to jump into a potentially expensive, time-intensive training program unless you know it's the right program and career path for you. Instead, explore the field by picking and choosing from this list of 10 mix-and-match steps to get a sense of the technology job world and what you're likely to find fulfilling.`
const mentorship =`Mentorships is a relationship between a knowledgeable or experienced person who helps a less experienced person. The mentor could be either younger or older than the person being mentored, but the mentor must have an area of expertise. Mentors are helpful because they have a network of business professionals and are willing to share who and what they know.`
const inclusion = `The coming together of people of different ethnicities with different experiences in cities and societies is a key driver of innovation. The food that we eat every day is a result of this blending of cultures. The most successful musical genres, such as jazz, rock’n’roll or hip-hop, are the products of cultural amalgamation. Business has the transformative power to change and contribute to a more open, diverse and inclusive society. We can only accomplish this by starting from within our organizations. Many of us know intuitively that diversity is good for business.`
const description = `We are building a caring community which is open, inclusive, free and supportive. We united profit with purpose to ensure you grow as the business grows.`
const Careeer = () => {
  return (
    <>
      <Layout>
        <Hero text="Welcome!" />
        <SEO title="Growing in Business and Technology Consulting" description={description}/>
        <MiniPage title="Inclusive Career" text={text} />
        <MiniPage title="Mentorship" text={mentorship} />
        <MiniPage title="Inclusion & Diversity" text={inclusion} />
      </Layout>
    </>
  )
}

export default Careeer
